import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHashtag } from "@fortawesome/free-solid-svg-icons";
import styles from "./Experience.module.css";

function Experience() {
  const experiences = [
    {
      company: "Zensar Technologies",
      location: "Pune, India",
      role: "Full-Stack Developer",
      period: "Dec 2021 - Dec 2022",
      details: [
        "Implemented websites and applications, using Angular, Node JS, MongoDB to create reusable, highly configurable components that increased development efficiency by 30%.",
        "Led the integration of third-party services to align with client specifications, optimizing performance and functionality, resulting in a 25% improvement in user satisfaction.",
        "Coordinated and presented sprint review demos to clients, receiving a 95% satisfaction rating and facilitating actionable feedback loops for continuous improvement.",
        "Collaborated closely with cross-functional teams in Daily Scrum meetings to address task-related issues promptly, contributing to a 20% reduction in project timeline setbacks.",
        "Recognized for delivering high-quality code with a 95% success rate in resolving application defects within deadlines.",
        "Deployed code on the server, ensuring smooth and efficient deployment processes and maintaining continuous integration/continuous deployment (CI/CD) pipelines."
      ],
    },
    {
      company: "Persistent Systems",
      location: "Pune, India",
      role: "Front-End Developer",
      period: "Aug 2019 - Dec 2021",
      details: [
        "Played a key role in building web applications using React, Angular, HTML, CSS, and Bootstrap, exceeding client expectations by 40%.",
        "Achieved an 80% improvement in website accessibility by implementing ADA compliance measures and addressing defects in accordance with WCAG guidelines following all Web Accessibility standards",
        "Managed version control using Git and collaborated with the team through JIRA, maintaining an organized development environment.",
        "Participated in project planning, delivered code reviews, debugged problems, providing feedback relative to best practices."
      ],
    },
  ];

  return (
    <div className={`container ${styles.customHeight}`}>
      <div className="row">
        <div className="col-12 text-center mb-4">
          <h2 className={`display-4 mb-4 ${styles.textColor} ${styles.heading}`}>
            WORK EXPERIENCE
          </h2>
          <p className={`lead ${styles.textColor} ${styles.lead}`}>
            Embark on a journey through my professional timeline, where each
            experience has contributed to my growth over the past three years.
            From crafting immersive web experiences to optimizing performance
            and functionality, my work reflects a commitment to excellence and a
            passion for innovation.
          </p>
        </div>
      </div>
      <div className={`row ${styles.experienceContainer}`}>
        {experiences.map((exp, index) => (
          <div key={index} className={`col-12 col-md-6 ${styles.experienceColumn}`}>
            <div className={`${styles.headerCompany}`} >
              <h3 className={styles.companyName}>
              {exp.role}
                
              </h3>
              <p className={styles.companyDetails}>
                <strong>
                {exp.company} <br /> <span>{exp.location}</span>  <br /> {exp.period}
                </strong>
              </p>
              <ul className={`list-unstyled ${styles.taskList}`}>
                {exp.details.map((detail, i) => (
                  <li key={i} className="d-flex align-items-start">
                    <div className={`me-2 ${styles.taskIcon}`}>
                      <FontAwesomeIcon icon={faHashtag} />
                    </div>
                    <p className={`${styles.details}`}>{detail}</p>
                  </li>
                ))}
              </ul>
            </div>
            {/* Add a horizontal line after the "Zensar Technologies" experience in mobile view */}
            {index === 0 && (
              <div className={`horizontal-line ${styles.horizontalLine}`} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Experience;
